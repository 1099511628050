<template>
	<div>

		<div class="text-center pa-40-pc pa-10-m bg-base top-line-primary under-line text-16 size-px-12-m justify-center-pc items-center gap-20">
			<template
				v-if="is_confirm"
			>
				<div class="justify-space-between items-center">
					<input v-model="item_present.member_id" class="input-box-inline text-16 size-px-12-m  flex-1" placeholder="선물할 대상을 선택하세요" readonly />
					<button
						class="btn-default text-16 size-px-12-m"
						@click="onSearch"
					>검색</button>
				</div>

				<div class="mt-10-m">
					<input v-model="item_present.memo" class="input-box-inline text-16 size-px-12-m width-90 bg-white mr-10" placeholder="선물 메시지를 입력하세요" />
				</div>

				<div class="mt-10-m justify-space-between items-center">
					<input v-model="item_present.member_point" class="input-box-inline text-16 size-px-12-m bg-white flex-1" placeholder="포인트를 입력하세요" :rules="[$rules.max(item_present, 'member_point', 9)]" type="number"  />
					<button
						v-if="is_confirm"
						class="btn-primary text-16 size-px-12-m"
						:disabled="is_disabled_present"

						@click="onPresent"
					>선물하기</button>
				</div>

				<div class="mt-10-m">
					<button
						class="btn-primary-outline text-16 size-px-12-m"
						@click="toPoint"
					>포인트 충전하기</button>
				</div>
			</template>
			<div
				v-else
			>
				선물하기는 본인인증 후 가능합니다
				<button
					class="btn-primary text-16 size-px-12-m ml-20"
					@click="onConfirm('present')"

				>본인인증</button>
			</div>
		</div>

		<search
			v-if="program.search"
			:program="program"
			:search="item_search"
			:option="item_search_option"

			@click="getSearch"
			class="mt-30"
		>

		</search>

		<div
			v-if="items_point.length > 0"
		>
			<ul class="hide-pc mt-10">
				<li
					v-for="(item, index) in point_list"
					:key="'item_' + index"
					class="li-point-list under-line box-shadow box pa-10 mt-20"
				>
					<div>
						<div>사용 일시</div>
						<div>{{ item.wDate }}</div>
					</div>
					<div>
						<div>구분</div>
						<div>{{ item.use_div_name }}</div>
					</div>

					<div>
						<div>대상</div>
						<div>{{ item.admin_id }}({{ item.present_name }})</div>
					</div>
					<div>
						<div>휴대폰 번호</div>
						<div>{{ item.present_tell || item.present_phone }}</div>
					</div>
					<div>
						<div>내용</div>
						<div>{{ item.memo }}</div>
					</div>
					<div>
						<div>사용 포인트</div>
						<div :class="'color-' + item.use_type_color">{{  item.use_point | makeComma}} P</div>
					</div>
					<div>
						<div>잔여 포인트</div>
						<div class="font-weight-bold">{{ item.point_now | makeComma }}P</div>
					</div>
				</li>
			</ul>
			<table
				class="table td-bold hide-m"
			>
				<colgroup>
					<col width="60px"/>
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
				</colgroup>
				<thead>
				<tr>
					<th>No</th>
					<th>일시</th>
					<th>구분</th>
					<th>아이디</th>
					<th>이름</th>
					<th>휴대폰 번호</th>

					<th>내용</th>
					<th>포인트</th>
					<th>잔여 포인트</th>
				</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item, index) in point_list"
						:key="'item_' + index"
					>
						<td>{{  item.no }}</td>
						<td>{{ item.wDate }}</td>
						<td :class="'color-' + item.use_type_color">{{ item.use_div_name }}</td>
						<td>{{ item.admin_id }}</td>
						<td>{{ item.present_name }}</td>

						<td>{{ item.present_tell || item.present_phone }}</td>
						<td>{{ item.memo }}</td>
						<td :class="'color-' + item.use_type_color">{{  item.use_point | makeComma}} P</td>
						<td>{{ item.point_now | makeComma }}P</td>
					</tr>
				</tbody>
			</table>

			<Pagination
				:program="program"
				:align="'center'"
				:options="item_search"

				@click="getData"

				class="mt-auto pa-10"
			></Pagination>
		</div>

		<Empty
			v-else
		></Empty>

		<PopupLayer
			v-if="is_on_search"
		>
			<template
				v-slot:body
			>
				<div class=" bg-white radius-top-20">
					<div class="pa-20 bg-popup-title flex-row justify-space-between items-center">
						<h4>선물 대상 조회</h4>
						<v-icon
							@click="is_on_search = false"
						>mdi-close-circle</v-icon>
					</div>

					<hr class="under-line ">

					<div class="pa-20 flex-row justify-space-between items-center under-line">

						<input v-model="item_search_member.keyword" class="input-box-inline flex-1 text-16 size-px-12-m" placeholder="2자리 이상 아이디 또는 휴대폰 번호" @keyup.enter="getMember" :rules="$rules.max(item_search_member, 'keyword', 12)"/>

						<button
							class="btn-primary pa-20 text-16 size-px-12-m"

							@click="getMember"
							:disabled="is_disabled_member"
						>검색</button>
					</div>

					<div class=" pa-10">
						<ul
							v-if="items_member.length > 0"
						>
							<li
								v-for="(member, m_index) in items_member"
								:key="'member_' + m_index"
								class="pa-10 box mb-10 justify-space-between items-center"
								:class="{'bg-gray-light': member.uid == item_member.uid}"
							>
								<div>
								{{ member.member_id }}
								({{ member.member_name }})
								{{ member.member_tell ||  member.member_phone}}
								</div>

								<button
									@click="setItem(member)"
									class="btn-primary-outline"
								>선택</button>
							</li>
						</ul>
						<Empty
							v-else
							text="선물 대상을 조회하세요"
						></Empty>
					</div>
				</div>
			</template>
		</PopupLayer>

		<PopupConfirm
			v-if="is_on_present"

			@cancel="is_on_present = false"
			@click="postPresent"
		>
			<template
				v-slot:title
			>포인트 선물하기</template>
			<template
				v-slot:main-txt
			>{{ item_present.member_name}}님에게 {{ item_present.member_point | makeComma}} 포인트를 선물하시겠습니까?</template>
			<template
				v-slot:name-confirm
			>선물하기</template>
		</PopupConfirm>
	</div>
</template>

<script>
import Search from "@/view/Layout/Search";
import Pagination from "@/components/Pagination";
import PopupLayer from "@/view/Layout/PopupLayer";
import Empty from "@/view/Layout/Empty";
import PopupConfirm from "@/view/Layout/PopupConfirm";
export default {
	name: 'PointPresent'
	,
	components: {PopupConfirm, Empty, PopupLayer, Pagination, Search},
	props: ['user']
	, data: function(){
		return {
			program: {
				name: '포인트 선물'
				, title: true
				, bottom: true
				, top_line: true
				, search: true
			}
			, item_present: {
				member_id: ''
				, member_point: ''
				, memo: ''
				, member_name: ''
			}
			, items_point: []
			, item_search: {
				page: 1
				, list_cnt: 10
				, cnt: 0
				, tCnt: 0
				, sDate: this.$date.getLastDate(7,'-')
				, eDate: this.$date.getToday('-')
				, use_type: ''
				, use_div: 'present'
			}
			, item_search_option: {
				sDate: true
				, eDate: true
				, is_keyword: true
				, is_cnt: true
				, select: [
					{ name: '적립 구분', column: 'use_type', items: [
							{ name: '받은 선물', column: '+'}
							,{ name: '보낸 선물', column: '-'}
						]
					}
				]
			}
			, is_on_search: false
			, items_member: []
			, item_member: {

			}
			,item_search_member: {
				keyword: ''
			}
			, is_on_present: false
			, is_confirm: false
		}
	}

	, computed:{
		point_list: function(){
			return this.items_point.filter( (item, index) => {
				item.no = this.item_search.tCnt - (this.item_search.page * this.item_search.list_cnt - this.item_search.list_cnt) - index


				return item
			})
		}
		, is_disabled_present: function(){
			let t = true
			// console.log(this.item_present.member_id, Number(this.item_present.member_point))
			if(this.item_present.member_id && Number(this.item_present.member_point) > 0){
				t = false
			}
			return t
		}
		, is_disabled_member: function(){
			let t = true
			if(this.item_search_member.keyword.length > 1){
				t = false
			}
			return t

		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'member/getPointList'
					, data: this.item_search
				})

				if(result.success){
					this.items_point = result.data.result
					this.item_search.tCnt = result.data.tCnt
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(){
			this.item_search.page = 1

			this.getData()
		}
		, onSearch: function(){
			this.is_on_search = true
		}
		, setItem: function(item){
			this.item_present.member_id = item.member_id
			this.item_present.member_name = item.member_name
			this.is_on_search = false
		}
		, getMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'member/getPresentMember'
					, data: {
						keyword: this.item_search_member.keyword
					}
				})

				if(result.success){
					this.items_member = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPresent: function(){
			this.is_on_present = true
		}
		, postPresent: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: 'member/postPresent'
					, data: this.item_present
				})

				if(result.success){
					this.item_present = {
						member_id: ''
						, member_point: ''
						, memo: ''
					}
					this.is_on_present = false
					this.$bus.$emit('setUser', [{ key: 'member_point', val: result.data.member_point}])
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toPoint: function(){
			this.$bus.$emit('to', { name: 'PointItem'})
		}
		, onConfirm: function(type){

			let baseUrl = this.$baseUrl

			this.nice_url = baseUrl + '/nice/request?type=' + type + '&P=' + sessionStorage.getItem(process.env.VUE_APP_NAME + 'AT')

			window.open(this.nice_url, 'nice', 'width=320px')
		}
		, niceResult: function(e){

			try {
				let domains = process.env.VUE_APP_DOMAIN.split('|')
				let t = false
				for(let i = 0; i < domains.length; i++){
					if(e.origin.indexOf(domains[i]) > -1){
						t = true
					}
				}
				if(t){
					let data = e?.data
					if(data && typeof data == 'string'){
						data = JSON.parse(data)

						if(data.type == 'nice_result'){
							if(data.result){
								this.is_confirm = true
								this.$bus.$emit('notify', { type: 'success', message: '본인인증이 완료되었습니다'})
								let now = new Date()
								sessionStorage.setItem('present_confirm', now)
							}else{
								throw data.message
							}
						}
					}
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				// this.is_on_confirm = false
			}
		}
	}
	, destroyed() {
		window.removeEventListener('message', this.niceResult)
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()

		window.removeEventListener('message', this.niceResult)
		setTimeout( () => {
			window.addEventListener('message', this.niceResult)
		}, 100)

		let c = sessionStorage.getItem('present_confirm')
		if(c){
			this.is_confirm = true
		}
	}
	, watch: {
		'item_present.member_point': {
			handler: function(call){

				if(Number(call) > this.user.member_point){
					this.$bus.$emit('notify', { type: 'error', message: '보유 포인트를 초과하였습니다.'})
					this.item_present.member_point = this.user.member_point
				}
			}
		}
	}
}
</script>

<style>

	.li-point-list {

	}

	.li-point-list > div{
		display: flex; justify-content: space-between; align-items: center;
	}
	.li-point-list > div:not(:first-child){
		margin-top: 10px;
	}
</style>